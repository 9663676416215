<template>
  <b-row ref="shortPrint">
    <!-- First column -->
    <b-col cols="12" sm="12" md="12" lg="12" xl="3">
      <b-row>

        <b-col cols="12">
          <b-row class="justify-content-md-center" align-h="center">
            <b-col cols="6" class="mt-2 text-center">
              <b-avatar rounded :src="avatar" variant="light" badge badge-offset="-1.5em" badge-variant="white" class="w-third float-image">
                <template #badge>
                  <b-img :src="getClassNetworkSpan('twitter')" class="margin-icon"/>
                </template>
              </b-avatar>
              <b-img class="show-image-sm" :src="require('@/assets/images/logo/logo-brandme1.png')"/>
            </b-col>
          </b-row>
        </b-col>

        <b-col cols="12" class="mt-10">
          <h1 class="text-center summary-username">@{{data.report.basic.username}}</h1>
        </b-col>
        <b-col cols="12">
          <h1 class="text-center summary-title">{{data.report.basic.title}}</h1>
        </b-col>

        <b-col cols="12">
          <b-card no-body class="no-shadow mt-2">
            <b-card-body class="d-flex justify-content-center align-items-center">
              <div class="text-center">
                <b-avatar size="40px" rounded class="bg-primary bg-lighten-2 mb-1">
                  <feather-icon size="20" icon="UserIcon"></feather-icon>
                </b-avatar>
                <div class="profile-user-info">
                  <h2 class="mb-0 font-weight-bolder">
                    {{ subs }}
                  </h2>
                  <b-card-text class="text-muted mt-1"><h4>{{$t('campaigns.followers')}}</h4></b-card-text>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>

        <b-col cols="12">
          <b-card no-body class="no-shadow mt-2">
            <b-card-body class="d-flex justify-content-center align-items-center">
              <div class="text-center">
                <b-avatar size="40px" rounded class="bg-success bg-lighten-2 mb-1">
                  <feather-icon size="20" icon="BookOpenIcon"></feather-icon>
                </b-avatar>
                <div class="profile-user-info">
                  <h2 class="mb-0 font-weight-bolder">
                    {{ languages }}
                  </h2>
                  <b-card-text class="text-muted"><h4>{{$t('youtube.idioms')}}</h4></b-card-text>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>

      </b-row>
    </b-col>
    <!-- End First column -->

    <!-- Second column -->
    <b-col cols="12" sm="12" md="12" lg="12" xl="9">
      <b-row>
        <b-col sm="12" md="12" lg="12" xl="6" class="ml-3">
          <h2 class="mb-2 subtitle-summary font-weight-bolder">{{$t('twitter.averageRes')}}</h2>
          <p class="mb-2"><strong class="h3">
            <span v-if="reply_avg.value > 0" class="text-success"><feather-icon size="20" :icon="'ArrowUpIcon'" /></span> 
            <span v-else class="text-danger"><feather-icon size="20" :icon="'ArrowDownIcon'" /></span> 
            {{reply_avg.value}} %</strong>
          </p>
          <p class="text-muted" v-if="reply_avg.performance !== 0"> Últimos {{reply_avg.performance}} dias</p>
        </b-col>

        <b-col sm="12" md="12" lg="12" xl="5">
          <h2 class="mb-2 subtitle-summary font-weight-bolder ml-3">{{$t('twitter.engagementRate')}}</h2>
          <h3 class="mb-2 ml-3">
            <span v-if="er.value > 0" class="text-success"><feather-icon size="20" :icon="'ArrowUpIcon'" /></span> 
            <span v-else class="text-danger"><feather-icon size="20" :icon="'ArrowDownIcon'" /></span> 
            <strong>{{decimalFixed(er.value, 2)}} %</strong>  
          </h3>
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="12" md="12" lg="12" xl="6" class="ml-3">
          <h2 class="mb-2 subtitle-summary font-weight-bolder">{{$t('instagram.increaseFollowers')}}</h2>
          <b-row>
            <b-col class="mb-4 p-0" sm="12" md="12" lg="12" xl="4" v-for="(item, index) in seriesGrowth()" :key="index">
                <div class="col-12 p-0">
                    <div class="col-12" v-if="seriesGrowth().length - 1 !== index">
                      <strong class="h3">{{percentage()[index]}} %</strong>
                      <span v-if="item > 0" class="text-success ml-1 mr-1"><feather-icon size="18" :icon="'ArrowUpIcon'" /></span> 
                      <span v-else class="text-danger ml-1 mr-1"><feather-icon size="18" :icon="'ArrowDownIcon'" /></span> 
                      <span class="h5">{{subscribers_count()[index]}} {{$t('campaigns.followers')}}</span>
                      <span class="d-block">{{$t('twitter.last')}} {{days[index]}} {{$t('twitter.days')}}</span>
                    </div>
                    <div class="col-12" v-else>
                      <span v-if="item > 0" class="text-success mr-1"><feather-icon size="18" :icon="'ArrowUpIcon'" /></span> 
                      <span v-else class="text-danger mr-1"><feather-icon size="18" :icon="'ArrowDownIcon'" /></span> 
                      <span class="h5">{{subscribers_count()[index]}} {{$t('campaigns.followers')}}</span>
                      <span class="d-block">{{$t('twitter.last')}} {{days[index]}} {{$t('twitter.days')}}</span>
                    </div>
                </div>
            </b-col>
          </b-row>
        </b-col>

        <b-col sm="12" md="12" lg="12" xl="12">
          <h2 class="mb-2 subtitle-summary font-weight-bolder ml-3">Promedio de interacciones</h2>
          <AverageEngagement :audienceData="data.report.metrics" v-if="showGraph"/>
        </b-col>
      </b-row>
    </b-col>
    <!-- End second column -->

    <b-col cols="12" v-if="!printing && !is_web" class="text-right">
      <b-button
        variant="primary"
        class="hide-sm"
        @click="print()"
      >
        {{$t('printSummary')}}
      </b-button>
    </b-col>

  </b-row>
</template>
<script>
import { BRow, BCol, BButton, BCard, BCardBody, BAvatar, BCardText, BImg } from 'bootstrap-vue'
import AverageEngagement from '@/views/pages/profile/twitter/charts/averageEngagement.vue';
import { print, getAvaibleData, getMotivationalPhrase, getLanguage } from '@/libs/utils/others';
import { getClassNetworkSpan } from '@/libs/utils/icons'
import { getFormat, urlImagetoBase64, convertData, decimalFixed, getPercentagePerformance, separatebycomma } from '@/libs/utils/formats';
import { getSeries, } from '@/libs/utils/series_graphs';

export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    avatar: {
      type: String,
      default: () => ''
    },
    format: {
      type: String,
      default: 'pdf'
    },
    is_web: {
      type: Boolean,
      default: false
    }
  },
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BCardBody,
    BAvatar,
    BCardText,
    AverageEngagement,
    BImg
  },
  data () {
    return {
      getLanguage,
      getMotivationalPhrase,
      separatebycomma,
      getPercentagePerformance,
      decimalFixed,
      getAvaibleData,
      getSeries,
      convertData,
      urlImagetoBase64,
      getFormat,
      getClassNetworkSpan,
      days: [30, 90, 180],
      printing: false,
      showGraph: false,
      print_image: require('@/assets/images/logo/logo_print.png'),
      src_img: '',
      interval_loading: null
    }
  },
  created() {
    const self = this;
    setTimeout(function() {
      self.showGraph = true;
      if (!self.is_web) self.print()
    }, 100);

    urlImagetoBase64(this.print_image, function (result) {
      self.src_img = result
    });
  },
  methods: {
    percentage() {
      return getPercentagePerformance(this.seriesGrowth())
    },
    subscribers_count() {
      const formatted_array = []
      getSeries(convertData(this.data.report.metrics.subscribers_count.performance),'value').forEach((item) => {
        formatted_array.push(separatebycomma(item))
      })
      return formatted_array
    },
    seriesGrowth() {
      return getSeries(convertData(this.data.report.metrics.subscribers_growth_prc.performance),'value')
    },
    print() {
      this.printing = true;
      const self = this;
      this.$emit('toogleIconClose', true)
      const name_file = `${self.$t('profile.summary')}_${self.data.report.basic.username}_${self.$route.params.network}_BrandMe`
      this.$vs.loading({
        type: 'sound',
        background: 'rgba(255, 255, 255, 1)',
        text: getMotivationalPhrase(),
      });

      this.interval_loading = setInterval(() => {
        const prev_loading = document.getElementsByClassName('con-vs-loading')[0]

        if (prev_loading) {
          prev_loading.classList.add('fade-out');
          setTimeout(function() {
            prev_loading.remove()
          }, 500);

          this.$vs.loading({
            type: 'sound',
            background: 'rgba(255, 255, 255, 1)',
            text: getMotivationalPhrase(),
          });
        }
      }, 5000);
      setTimeout(function() {
        print('l', name_file, self.$parent.$parent.$refs.body, function () {
          self.printing = false;
          clearInterval(self.interval_loading)
          self.$vs.loading.close()
          self.$emit('toogleIconClose', false)
          self.$emit('closeModal')
        }, self.format)
      }, 500);
    }
  },
  computed: {
    subs() {
      return getFormat(this.data.report.metrics.subscribers_count.value)
    },
    languages() {
      const lang = this.data.report.features.blogger_languages.data.map(code => getLanguage(code))
      return lang.reduce((str, name) => `${str},${name}`)
    },
    seriesSubs() {
      return getSeries(convertData(this.data.report.metrics.subscribers_count.performance),'value')
    },
    seriesRetweet() {
      return getSeries(convertData(this.data.report.metrics.retweet_avg.performance),'value')
    },
    seriesFav() {
      return getSeries(convertData(this.data.report.metrics.favorite_avg.performance),'value')
    },
    seriesReply() {
      return getSeries(convertData(this.data.report.metrics.reply_avg.performance),'value')
    },
    reply_avg() {
      return getAvaibleData(this.data.report.metrics.reply_avg.performance)
    },
    er() {
      return getAvaibleData(this.data.report.metrics.er.performance)
    },
  }
}
</script>

<style type="text/css">
  .no-shadow {
    box-shadow: none;
  }
  .margin-icon {
    width: 5.1em;
  }
</style>
