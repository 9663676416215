<template>
  <b-row ref="shortPrint">
    <!-- First column -->
    <b-col cols="12" sm="12" md="12" lg="12" xl="3">
      <b-row>

        <b-col cols="12">
          <b-row class="justify-content-md-center" align-h="center">
            <b-col cols="6" sm="4" md="4" lg="4" xl="5" class="mt-2 text-center">
              <b-avatar rounded :src="avatar" variant="light" badge badge-offset="-1.5em" badge-variant="white" class="w-third float-image">
                <template #badge>
                  <i :class="`${getClassNetworkSpan('twitch')} margin-icon font-30`"/>
                </template>
              </b-avatar>
              <b-img class="show-image-sm" :src="require('@/assets/images/logo/logo-brandme1.png')"/>
            </b-col>
          </b-row>
        </b-col>

        <b-col cols="12" class="mt-10">
          <h1 class="text-center summary-username">@{{data.report.basic.username}}</h1>
        </b-col>
        <b-col cols="12">
          <h1 class="text-center summary-title">{{data.report.basic.title}}</h1>
        </b-col>

        <b-col cols="12">
          <b-card no-body class="no-shadow mt-2">
            <b-card-body class="d-flex justify-content-center align-items-center">
              <div class="text-center">
                <div class="profile-user-info">
                  <h2 class="mb-0 font-weight-bolder">
                    {{ subs }}
                  </h2>
                  <b-card-text class="text-muted mt-1"><h4>{{$t('campaigns.followers')}}</h4></b-card-text>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>

        <b-col cols="12">
          <b-card no-body class="no-shadow mt-2">
            <b-card-body class="d-flex justify-content-center align-items-center">
              <div class="text-center">
                <div class="profile-user-info">
                  <h2 class="mb-0 font-weight-bolder">{{ viewsFormat }}</h2>
                  <b-card-text class="text-muted mt-1"><h4>{{$t('youtube.views')}}</h4></b-card-text>
                </div>
              </div>
            </b-card-body>
          </b-card>

        </b-col>

      </b-row>
    </b-col>
    <!-- End First column -->

    <!-- Second column -->
    <b-col cols="12" sm="12" md="12" lg="12" xl="9">
      <b-row class="match-height">
        <b-col sm="12" md="12" lg="12" xl="6">
          <b-card no-body class="no-shadow">
            <b-card-header>
              <b-card-title class="subtitle-summary">
                  {{$t('instagram.ageRange')}}
              </b-card-title>
            </b-card-header>
            <b-card-body class="pb-0">
              <div  class="d-flex justify-content-start mb-3">
                <div class="mr-2">
                  <b-card-text class="mb-50">
                    {{$t('instagram.female')}}
                  </b-card-text>
                  <h3 class="font-weight-bolder">
                    <span style="color:#E75480">{{ gendersTotal[1] }}%</span>
                  </h3>
                </div>
                <div class="mr-2">
                  <b-card-text class="mb-50">
                    {{$t('instagram.male')}}
                  </b-card-text>
                  <h3 class="font-weight-bolder">
                    <span style="color:#5cb3ff">{{ gendersTotal[0] }}%</span>
                  </h3>
                </div>
              </div>
              <vue-apex-charts type="line" height="200" :options="chartOptions" :series="series" v-if="showGraph" :key="showGraph"></vue-apex-charts>
            </b-card-body>
          </b-card>
        </b-col>

        <b-col sm="12" md="12" lg="12" xl="6">
          <statistic-card-with-line-chart
              class="no-shadow"
              customClassTitle="subtitle-summary"
              icon="EyeIcon"
              :statistic="$t('youtube.averageViews')"
              :statistic-title="$t('twitch.duringlast')"
              :statisticText="`${this.$t('twitch.average')}: ${getFormat(data.report.metrics.views_avg.performance.all.value)}`"
              :chart-data="[{name: $t('youtube.views'), data: seriesViews}]"
              :color="'successs'"
              :chartOptions="options"
              :heightChart="180"
              :key="showGraph"
              v-if="showGraph"
            />
        </b-col>

        <b-col sm="12" md="12" lg="12" xl="6">
          <b-card no-body class="no-shadow">
            <b-card-header>
              <b-card-title class="subtitle-summary">
                {{$t('instagram.mainCountries')}}
              </b-card-title>
            </b-card-header>
            <b-card-body>
              <vue-apex-charts type="bar" height="300" :options="countryOptions" :series="[{name: $t('youtube.countries'), data: countriesSeries}]" :key="showGraph" v-if="showGraph"></vue-apex-charts>
            </b-card-body>
          </b-card>
        </b-col>

        <b-col sm="12" md="12" lg="12" xl="6">
          <statistic-card-with-line-chart
              class="no-shadow"
              customClassTitle="subtitle-summary"
              icon="EyeIcon"
              :statistic="$t('twitch.averageViewsLive')"
              :statistic-title="$t('twitch.duringlast')"
              :statisticText="`${this.$t('twitch.average')}:${getFormat(data.report.metrics.live_viewers_avg.performance.all.value)}`"
              :chart-data="[{name: $t('youtube.views'), data: seriesViewsLive}]"
              :color="'successs'"
              :chartOptions="options"
              :heightChart="180"
              :key="showGraph"
              v-if="showGraph"
            />
        </b-col>
      </b-row>
    </b-col>
    <!-- End second column -->

    <b-col cols="12" v-if="!printing && !is_web" class="text-right">
      <b-button
        variant="primary"
        class="hide-sm"
        @click="print()"
      >
        {{$t('printSummary')}}
      </b-button>
    </b-col>

  </b-row>
</template>
<script>
import { BRow, BCol, BButton, BCard, BCardText, BCardBody, BAvatar, BCardHeader, BCardTitle, BImg } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import StatisticCardWithLineChart from '@core/components/statistics-cards/StatisticCardWithLineChart.vue'
import { print, getMotivationalPhrase, getCountry } from '@/libs/utils/others';
import { getClassNetworkSpan } from '@/libs/utils/icons'
import { getFormat, urlImagetoBase64, convertData } from '@/libs/utils/formats';
import { getSeries, } from '@/libs/utils/series_graphs';

export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    avatar: {
      type: String,
      default: () => ''
    },
    format: {
      type: String,
      default: 'pdf'
    },
    is_web: {
      type: Boolean,
      default: false
    }
  },
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BCardText,
    BCardBody,
    BAvatar,
    BCardHeader,
    BCardTitle,
    VueApexCharts,
    StatisticCardWithLineChart,
    BImg
  },
  data () {
    return {
      getCountry,
      getMotivationalPhrase,
      convertData,
      getSeries,
      urlImagetoBase64,
      getFormat,
      getClassNetworkSpan,
      printing: false,
      showGraph: false,
      chartOptions: {
        chart: {
          toolbar: { show: false },
          zoom: { enabled: false },
          type: 'line',
          offsetX: -10,
        },
        stroke: {
          curve: 'smooth',
          dashArray: [0],
          width: [4, 3],
        },
        legend: {
          show: false,
        },
        colors: ['#5cb3ff', '#E75480'],
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            inverseColors: false,
            gradientToColors: ['#5cb3ff', '#E75480'],
            shadeIntensity: 1,
            type: 'horizontal',
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100],
          },
        },
        markers: {
          size: 0,
          hover: {
            size: 5,
          },
        },
        xaxis: {
          title: {
            text:`${this.$t('instagram.ageRange')}`,
            offsetY: -10,
            style: {
              fontWeight: 400,
              fontSize: '1rem',
              color: '#b9b9c3'
            }
          },
          labels: {
            style: {
              colors: '#b9b9c3',
              fontSize: '1rem',
            },
          },
          axisTicks: {
            show: false,
          },
          categories: ['13-17', '18-24', '25-34', '35-44', '45-54', '55-64', '65+'],
          axisBorder: {
            show: false,
          },
          tickPlacement: 'on',
        },
        yaxis: {
          tickAmount: 5,
          labels: {
            style: {
              colors: '#b9b9c3',
              fontSize: '1rem',
            },
            formatter(val) {
              return `${val}%`
            },
          },
        },
        grid: {
          borderColor: '#e7eef7',
          padding: {
            top: -20,
            bottom: -10,
            left: 20,
          },
        },
        tooltip: {
          x: { show: false },
        },
      },
      options: {
        grid: {
          show: false,
          padding: {
            left: 30,
            right: 30,
            bottom: 30
          },
        },
        chart: {
          type: 'line',
          dropShadow: {
            enabled: true,
            top: 5,
            left: 0,
            blur: 4,
            opacity: 0.1,
          },
          toolbar: {
            show: false,
          },
          sparkline: {
            enabled: true,
          },
        },
        stroke: {
          width: 5,
          curve: 'smooth',
        },
        xaxis: {
          type: 'category',
          categories: ['7d','30d', '90d', '180d', '365d'],
          show: true,
          labels: {
            style: {
              colors: '#b9b9c3',
              fontSize: '0.857rem',
            },
            show: true,
            offsetY: -5,

          }
        },
        colors: ['#A9A2F6'],
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            gradientToColors: ['#A9A2F6'],
            shadeIntensity: 1,
            type: 'horizontal',
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100],
          },
        },
        markers: {
          size: 0,
          hover: {
            size: 2,
          },
        },
        tooltip: {
          enabled: true,
          x: { show: true },
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show:true,
          position: 'top'
        }
      },
      print_image: require('@/assets/images/logo/logo_print.png'),
      src_img: '',
      interval_loading: null
    }
  },
  created() {
    const self = this;
    setTimeout(function() {
      self.showGraph = true;
      if (!self.is_web) self.print()
    }, 100);

    urlImagetoBase64(this.print_image, function (result) {
      self.src_img = result
    });
  },
  methods: {
    quitAll(data) {
      const arr = convertData(data)
      arr.pop()
      return arr
    },
    print() {
      this.printing = true;
      const self = this;
      this.$emit('toogleIconClose', true)
      const name_file = `${self.$t('profile.summary')}_${self.data.report.basic.username}_${self.$route.params.network}_BrandMe`
      this.$vs.loading({
        type: 'sound',
        background: 'rgba(255, 255, 255, 1)',
        text: getMotivationalPhrase(),
      });

      this.interval_loading = setInterval(() => {
        const prev_loading = document.getElementsByClassName('con-vs-loading')[0]

        if (prev_loading) {
          prev_loading.classList.add('fade-out');
          setTimeout(function() {
            prev_loading.remove()
          }, 500);

          this.$vs.loading({
            type: 'sound',
            background: 'rgba(255, 255, 255, 1)',
            text: getMotivationalPhrase(),
          });
        }
      }, 5000);
      setTimeout(function() {
        print('l', name_file, self.$parent.$parent.$refs.body, function () {
          self.printing = false;
          clearInterval(self.interval_loading)
          self.$vs.loading.close()
          self.$emit('toogleIconClose', false)
          self.$emit('closeModal')
        }, self.format)
      }, 500);
    }
  },
  computed: {
    countryOptions() {
      const options = {
        chart: {
          toolbar: {
            show: false,
          },
        },
        colors: '#4be3ac',
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '30%',
            endingShape: 'rounded',
          },
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: this.data.report.features.audience_geo.data?.countries?.map(item => { return getCountry(item.title ? item.title : item.id ? item.id : item.code) }),
          show: true,
          labels: {
            formatter: (val) => {
              return `${val}%`
            }
          }
        },
        tooltip: {
          y: {
            formatter: (val) => {
              return `${val}%`
            }
          }
        }
      }
      return options
    },
    genders() {
      const obj = []
      const keys = Object.keys(this.data.report.features.audience_age_gender.data ?? {})

      for (let index = 0; index < keys.length; index++) {
        obj.push({male: this.data.report.features.audience_age_gender.data[keys[index]].male, female: this.data.report.features.audience_age_gender.data[keys[index]].female})
      }

      return obj
    },
    subs() {
      return getFormat(this.data.report.metrics.subscribers_count.value ?? 0)
    },
    viewsFormat() {
      return getFormat(this.data.report.metrics.views_count.value ?? 0)
    },
    male() {
      const male = []
      this.genders.map(item => male.push(item.male))
      return male
    },
    female() {
      const female = []
      this.genders.map(item => female.push(item.female))
      return female
    },
    gendersTotal() {
      const reduceMale = (sum, item) => sum + item.male
      const reduceFemale = (sum, item) => sum + item.female
      const male = this.genders.reduce(reduceMale, 0)
      const female = this.genders.reduce(reduceFemale, 0)
      return [Math.round(male), Math.round(female)]
    },
    series() {
      return [{name: `${this.$t('instagram.male')}`, data: this.male}, {name: `${this.$t('instagram.female')}`, data: this.female}]
    },
    countriesSeries() {
      const serie = this.data.report.features.audience_geo.data?.countries?.map(item => { return item.prc })
      return serie
    },
    seriesViews() {
      return getSeries(this.quitAll(this.data.report.metrics.views_avg.performance),'value')
    },
    seriesViewsLive() {
      return getSeries(this.quitAll(this.data.report.metrics.live_viewers_avg.performance),'value')
    },
  }
}
</script>

<style type="text/css">
  .no-shadow {
    box-shadow: none;
  }
</style>
