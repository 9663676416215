<template>
  <b-card no-body>
    <b-card-body class="d-flex justify-content-between align-items-center pb-0">
      <div class="truncate">
        <h2 class="font-weight-bolder" :class="customClassTitle">
          {{ statistic }}
        </h2>
        <span>{{ statisticTitle }}</span>
      </div>
      <b-avatar
        :variant="`light-${color}`"
        size="45"
      >
        <feather-icon
          size="21"
          :icon="icon"
        />
      </b-avatar>
    </b-card-body>
    <b-card-body>
      {{ statisticText}}
    </b-card-body>

    <component
      :is="apex_charts"
      type="line"
      :height="heightChart"
      width="100%"
      :options="chartOptionsComputed()"
      :series="chartData"
      v-if="line_chart_options && apex_charts && theme_colors"
    />

  </b-card>
</template>

<script>
import { BCard, BCardBody, BAvatar } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardBody,
    BAvatar,
  },
  props: {
    icon: {
      type: String,
      required: true,
      default: ''
    },
    statistic: {
      type: [Number, String],
      required: true,
      default: ''
    },
    statisticTitle: {
      type: String,
      default: '',
    },
    statisticText: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'primary',
    },
    chartData: {
      type: Array,
      default: () => [],
    },
    chartOptions: {
      type: Object,
      default: null,
    },
    heightChart: {
      type: Number,
      default: 100
    },
    customClassTitle: {
      type: String,
      defautl: ''
    }
  },
  data() {
    return {
      apex_charts: null,
      line_chart_options: null,
      theme_colors: null,
    }
  },
  async mounted() {
    this.apex_charts = (await import('vue-apexcharts')).default;
    this.line_chart_options = (await import('./chartOptions')).lineChartOptions
    this.theme_colors = (await import('@themeConfig')).$themeColors
  },
  methods: {
    chartOptionsComputed() {
      if (this.chartOptions === null && this.line_chart_options && this.theme_colors) {
        const options = JSON.parse(JSON.stringify(this.line_chart_options))
        options.fill.gradient.gradientToColors = [this.gradientToColor(this.color)]
        options.colors = [this.theme_colors[this.color]]

        return options
      }
      return this.chartOptions
    },
    gradientToColor(color) {
      const gradientToColors = {
        primary: '#A9A2F6',
        success: '#55DD92',
        warning: '#ffc085',
        danger: '#F97794',
        info: '#59E0F0',
        secondary: '#B4B9BF',
        light: '#D0D4DB',
        dark: '#919191',
        instagram: 'linear-gradient(to right top, #4c63d2, #725ac7, #8d50ba, #a045aa, #af3b99, #c53d8c, #d6447e, #e25170, #f47166, #fd9262, #ffb466, #fed576)',
      }

      return gradientToColors[color]
    },
  },
}
</script>
