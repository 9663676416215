<template>
  <b-modal 
    v-model="show"
    class="text-center"
    centered
    hide-footer
    hide-header
    body-class="backgroud-print p-3 pt-5"
    dialog-class="w-full-modal"
    size="xl"
    ref="shortPrint"
  >
    <feather-icon
      icon="XIcon"
      size="24"
      class="icon-close"
      @click="closeModal()"
      v-if="!printing"
    />
    <b-img class="float-logo mb-2 hide-image-sm" :src="require('@/assets/images/pdf/logo.png')"/>
    <b-card class="mt-3 no-shadow">
      <summary-instagram v-if="$route.params.network === 'instagram'" :data="data" :avatar="avatar" :format="format" @closeModal="closeModal" @toogleIconClose="toogleIconClose"/>
      <summary-youtube v-if="$route.params.network === 'youtube'" :data="info" :avatar="avatar" :format="format" @closeModal="closeModal" @toogleIconClose="toogleIconClose"/>
      <summary-tiktok v-if="$route.params.network === 'tiktok'" :data="info" :avatar="avatar" :format="format" @closeModal="closeModal" @toogleIconClose="toogleIconClose"/>
      <summary-twitch v-if="$route.params.network === 'twitch'" :data="info" :avatar="avatar" :format="format" @closeModal="closeModal" @toogleIconClose="toogleIconClose"/>
      <summary-twitter v-if="$route.params.network === 'twitter'" :data="info" :avatar="avatar" :format="format" @closeModal="closeModal" @toogleIconClose="toogleIconClose"/>
    </b-card>
  </b-modal>
</template>
<script>
import { BModal, BCard, BImg } from 'bootstrap-vue'
import summaryInstagram from './summaryInstagram.vue'
import summaryYoutube from './summaryYoutube.vue'
import summaryTiktok from './summaryTiktok.vue'
import summaryTwitch from './summaryTwitch.vue'
import summaryTwitter from './summaryTwitter.vue'

export default {
  props: {
    modalShow: {
      type: Boolean,
      default: false
    },
    info: {
      type: Object,
      default: () => {}
    },
    avatar: {
      type: String,
      default: () => ''
    },
    format: {
      type: String,
      default: 'pdf'
    }
  },
  components: {
    BModal,
    BCard,
    BImg,
    summaryInstagram,
    summaryYoutube,
    summaryTiktok,
    summaryTwitch,
    summaryTwitter
  },
  data () {
    return {
      show: this.modalShow,
      data: {},
      printing: false
    }
  },
  created() {
    if (this.$route.params.network === 'instagram') this.data = this.info.main_child.detail;
  },
  methods: {
    closeModal() {
      this.show = false
    },
    toogleIconClose(flag) {
      this.printing = flag
    }
  }
}
</script>

<style type="text/css">
  .print-logo {
    width: 100px;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 2;
  }
  .backgroud-print {
    background: url('~@/assets/images/pdf/background.png') no-repeat center #eee;
    background-size: cover;
  }
  .logo-twitter {
    top: -4% !important;
  }
  .float-logo {
    position: absolute;
    right: 0;
    top: 0;
    width: 250px;
    z-index: 5000;
  }
  .w-third {
    width: 100%;
    height: auto;
    max-width: 334px;
  }
  .font-30 {
    font-size: 30px;
  }
  .margin-icon{
    z-index: 1;
    border-radius: 100%;
    padding: 6px;
    background: white;
    z-index: 2000 !important;
  }
  .mt-10 {
    margin-top: 14rem !important;
  }
  .float-image {
    position: absolute;
    top: -85px;
    right: 0;
  }
  .float-image img {
    z-index: 1000;
  }
  .float-image .b-avatar-badge {
    z-index: 2000 !important;
  }
  .summary-title {
    color: #0C23E2;
    font-size: 3rem;
  }
  .subtitle-summary {
    color: #0C23E2;
    font-size: 2.2rem !important;
    font-weight: 500;
  }
  .icon-close {
    position: absolute;
    top: 10px;
    left: 10px;
    color: #FDFFFF;
    cursor: pointer;
  }
  .icon-close:hover {
    background-color: rgb(248, 255, 255, 0.5);
    border-radius: 50%;
    padding: 2px;
  }
  .w-full-modal {
    max-width: 100%;
  }

  @media(min-width:1075px) and (max-width: 1999px){
    .mt-10 {
      margin-top: 19rem !important;
    }
  }
  @media(min-width: 2259px){
    .mt-10 {
      margin-top: 17rem !important;
    }
  }

  @media(min-width: 2595px){
    .mt-10 {
      margin-top: 19rem !important;
    }
  }

  @media(min-width: 2958px){
    .mt-10 {
      margin-top: 23rem !important;
    }
  }

  @media(min-width: 3336px){
    .mt-10 {
      margin-top: 27rem !important;
    }
  }

  @media(min-width: 3800px){
    .mt-10 {
      margin-top: 30rem !important;
    }
  }

  @media (max-width: 576px) {
    .hide-sm {
      display: none !important;
    }
  }

  @media (max-width: 759px) {
    .hide-image-sm {
      display: none !important;
    }
    .show-image-sm {
      display: flex !important;
      align-content: center;
      position: absolute;
      top: 125px;
      width: 120px;
    }
  }

  @media (min-width: 760px) {
    .show-image-sm {
      display: none !important;
    }
  }

</style>