  <template>
  <b-dropdown 
    no-caret 
    class="dropdown-color dropdown-color-secondary"
    :disabled="disabled"
    @hide="closedDropdown"
  >
    <template #button-content> 
      <b-button
        variant="outline-secondary" 
        :class="`outline-secondary-hover ${padding_custom}`"
        :disabled="disabled"
      >
      {{$t(text_button)}}
      </b-button>
    </template>
    <b-dropdown-header>
      <h6>{{$t('search.add_to_list')}}</h6>
      <hr class="m-0 mb-1">
      <b-col 
        v-for="(list, index) in lists"
        :key="index" 
        class="col-12 dropitem-add-campaign"
        :class="checkClassesInList(list)"
        @click="disabled ? toastSelectInfluencer() : handleCheck(list.value, index)"
        :ref="`disable-campaign-${index}`"
      >
        <div class="d-flex justify-content-between container-drop-items">
          <span class="d-block avenir-medium"> {{list.text.substr(0, 25)}}</span>
          <feather-icon
            v-if="lists_influencer.length > 0"
            :icon="checkInLists(list.value) ? 'CheckCircleIcon' : 'PlusIcon'" 
            :class="checkInLists(list.value) ? 'success-color-icon' : 'secondary-color-icon'">
          </feather-icon>
          <span v-else>
            <feather-icon icon="PlusIcon" :ref="`plus-icon-list-${index}`" class="icons-absolute-status"></feather-icon>
            <feather-icon icon="CheckCircleIcon" class="d-none icons-absolute-status success-color-icon" :ref="`check-icon-list-${index}`"></feather-icon>
          </span>
          <b-spinner class="spinner-dropdown-item d-none" :ref="`spinner-dropdown-c-${index}`"></b-spinner>
        </div>
      </b-col>
      <div v-if="lists.length === 0" class="avenir-medium">
        {{ $t('lists.noLists') }}
      </div>
      <hr>
      <b-link target="_blank" href="/lists" class="d-flex align-items-center"><feather-icon icon="ExternalLinkIcon" class="margin-right-05"></feather-icon> {{$t('search.go_to_lists')}}</b-link>
    </b-dropdown-header>
  </b-dropdown>
</template>

<script>
import {
  BDropdown, BDropdownHeader, BSpinner, BLink, BCol, BButton
} from 'bootstrap-vue'
import service_list from '@/services/lists'
import service_profile from '@/services/others';
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');

export default {
  name: 'dropdownLists',
  components: {
    BDropdown,
    BDropdownHeader,
    BSpinner,
    BLink,
    BCol,
    BButton
  },
  props: {
    lists: {
      type: Array,
      require: () => [],
    },
    influencers: {
      type: Array,
      default: () => []
    },
    lists_influencer: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: true
    },
    multiple_added: {
      type: Boolean,
      default: false
    },
    text_button: {
      type: String,
      default: 'search.list'
    },
    padding_custom: {
      type: String,
      default: 'padding-default'
    }
  },
  data() {
    return {
      selected_list: ''
    }
  },
  methods: {
    checkInLists(list_uuid) {
      const already_list = this.lists_influencer.find(list => list.uuid === list_uuid)
      if (already_list && already_list.with_influencer) return true
      else return false
    },
    checkClassesInList(list) {
      const in_list = this.checkInLists(list.value)
      if (in_list) return 'dropitem-add-campaign-disabled'
      else return ''
    },
    toastSelectInfluencer() {
      loadToastificationContent().then((module) => {
        this.$toast({
          component: module.default,
          position: "top-right",
          props: {
            title:  this.$t('search.check_influencer_title'),
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: this.$t('search.check_influencer_text')
          },
        });
      })
    },
    handleCheck(list_uuid = null, index_spinner = null) {
      const already_list = this.lists_influencer.find(list => list.uuid === list_uuid)
      if (already_list && already_list.with_influencer) return
      this.selected_list = list_uuid;
      this.$refs[`spinner-dropdown-c-${index_spinner}`][0].classList.remove('d-none');
      this.addCheckedInfluencer(index_spinner);
    },
    addCheckedInfluencer(index_spinner) {
      service_list.bulkAddInfluencer(this.selected_list, {influencers: this.influencers})
        .then(() => {
          const already_list = this.lists_influencer.find(list => list.uuid === this.selected_list)
          if (already_list) already_list.with_influencer = true
          this.selected_list = null;
          loadToastificationContent().then((module) => {
            this.$toast({
              component: module.default,
              props: {
                title: this.$t('search.added'),
                icon: 'CheckCircleIcon',
                text: this.$t('search.hasBeenAdded'),
                variant: 'success'
              },
            });
          })
          setTimeout(() => {
            this.$refs[`spinner-dropdown-c-${index_spinner}`][0].classList.add('d-none');
            this.$refs[`disable-campaign-${index_spinner}`][0].classList.add('dropitem-add-campaign-disabled');
            if (this.lists_influencer.length === 0) {
              this.$refs[`plus-icon-list-${index_spinner}`][0].classList.add('d-none');
              this.$refs[`check-icon-list-${index_spinner}`][0].classList.remove('d-none');
            }
          }, 500);
          this.$emit('addedList')

          service_profile.getMaxViews()
            .then(response => {
              this.$root.$emit('change_max_views', response.max_views)
            });
        });
    },
    closedDropdown() {
      if (this.lists_influencer.length === 0) {
        for (let i = 0; i < this.lists.length; i++) {
          this.$refs[`plus-icon-list-${i}`][0].classList.remove('d-none');
          this.$refs[`check-icon-list-${i}`][0].classList.add('d-none');
          this.$refs[`disable-campaign-${i}`][0].classList.remove('dropitem-add-campaign-disabled');
        }
      }
    },
  }
}
</script>

<style>
.padding-default {
  padding: 0.786rem 1.5rem;
}
.dropitem-add-campaign {
  padding: 0.5em;
}
.dropitem-add-campaign:hover {
  background-color: #EFF3F6;
  cursor: pointer;
}
.dropitem-add-campaign-disabled {
  color: #ccc;
}
.dropitem-add-campaign-disabled:hover {
  background-color: transparent;
  cursor: default;
}
.spinner-dropdown-item {
  position: absolute;
  right: 7px;
  background-color: white;
  height: 16px;
  width: 16px;
  color: #ccc;
}
.dropdown-color > button {
  padding: 0 !important;
  border: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
}
.dropdown-color > .dropdown-menu {
  margin-top: 5px !important;
}
.color-link {
  color: #7367f0 !important;
}
.success-color-icon {
  color: green;
}
.outline-secondary-hover {
  border-color: #82868b;
  color: #82868b;
}
.outline-secondary-hover:hover {
  color: #82868b;
  background-color: white;
}
.dropdown-color-secondary > .dropdown-toggle-no-caret:active, .dropdown-color-secondary > .dropdown-toggle-no-caret:focus {
  color: #82868b;
  background-color: white !important;
}
</style>
